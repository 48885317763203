import { useApi } from "../../api/ApiProvider";

type AddReviewsProps = {
    toggleForm: () => void;
}


const PrivacyPolicy = ({ toggleForm }: AddReviewsProps) => {
    const { politicContent } = useApi(); 
    return (
        <div className="fixed w-full h-full inset-0 z-50 overflow-hidden animated fadeInPrivacy faster flex items-center justify-center" 
        style={{ background: "rgba(0,0,0,.7)" }}>
            <div className="max-w-md  xl:max-w-5xl lg:max-w-5xl md:max-w-2xl mx-5 bg-white max-h-screen shadow-lg flex-row rounded relative">
                <div className="py-4 text-left px-6">
                    <div className="flex justify-end items-center pb-3">
                        <div className="cursor-pointer z-50 font-bold text-md  " onClick={toggleForm}>
                            ✕
                        </div>
                    </div>
                    <div className="max-h-[600px] overflow-y-auto my-5 modal-content  font-georgia max-md:text-sm  ">
                        <div dangerouslySetInnerHTML={{ __html: politicContent || "" }} />
                        <div className="flex justify-end ">
                            <button className="transition-opacity duration-150 mr-2 mb-5 mt-2
                            ease delay-100 hover:bg-slate-100 bg-white shadow-md 
                            rounded-[10px] w-[160px] h-[55px] text-[#202E40] font-georgia
                             text-lg font-bold  uppercase max-md:text-sm max-md:w-[120px] max-[500px]:h-[35px]" onClick={toggleForm}>закрыть</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy