import { useEffect, useState } from "react";
import { fetchBlogArticleCategories } from "../../api";

interface BlogCategory {
    name: string;
    description: string;
    preview: null;
    image: null;
    meta_title: string;
    meta_h1: string;
    meta_description: string;
    meta_keywords: null;
    seo_keyword: string;
    date_available: string;
}

const itemsPerPage = 6;

const Blog = () => {
    const [blogCategories, setBlogCategories] = useState<BlogCategory[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categories = await fetchBlogArticleCategories();
                setBlogCategories(categories);
            } catch (error) {
                console.error("Ошибка запроса:", error);
            }
        };

        fetchData();
    }, []);

    function truncateText(text: string, maxLength: number) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

    const totalItems = blogCategories.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const renderPageButtons = () => {
        const buttons = [];
        const maxButtonsToShow = 3;
        const halfMaxButtons = Math.floor(maxButtonsToShow / 2);

        let startPage = currentPage - halfMaxButtons;
        let endPage = currentPage + halfMaxButtons;

        if (startPage <= 0) {
            startPage = 1;
            endPage = Math.min(totalPages, maxButtonsToShow);
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - maxButtonsToShow + 1);
        }

        if (startPage > 1) {
            buttons.push(
                <button key="start" onClick={() => handlePageChange(1)}>
                    1
                </button>
            );
            if (startPage > 2) {
                buttons.push(<span key="dots-start" className="cursor-pointer m-0.5 p-2 bg-[#BBDBD7]
                 text-white border-none rounded-md transition duration-300  ">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    disabled={currentPage === i}
                    className={currentPage === i ? "active" : ""}
                >
                    {i}
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                buttons.push(<span key="dots-end" className="cursor-pointer m-0.5 p-2 bg-[#BBDBD7]
                text-white border-none rounded-md transition duration-300 ">...</span>);
            }
            buttons.push(
                <button key="end" onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </button>
            );
        }

        return buttons;
    };
    return (
        <div className="flex flex-col justify-center items-center ">
            <h1 className="text-black font-georgia text-[30px] text-center font-bold mb-5 max-xl:text-xl max-lg:text-lg  max-lg:mb-2">
                Статьи блога
            </h1>
            <div className="w-full mb-20 ">

                {blogCategories
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    .map((category, index) => {
                        const actualIndex = (currentPage - 1) * itemsPerPage + index;
                        return (
                            <div
                                key={category.seo_keyword}
                                className={` px-20 max-lg:px-10 py-10 max-lg:py-5 ${index % 2 === 0 ? "" : "bg-[#CEE1DE] w-full "}`} >
                                <div className="">
                                    <span className="mr-2">{actualIndex + 1}.</span>
                                    <a href={`/articles/${category.seo_keyword}`} className="
                                     font-georgia text-base max-xl:text-sm max-lg:text-xs font-bold uppercase text-black  hover:text-turquoise transition-opacity duration-150 ease delay-100">{category.name}</a>
                                </div>
                                <hr className="w-full border-t h-[1px] bg-black bg-opacity-10 mt-2 mb-2" />
                                <p className="text-black  font-georgia max-xl:text-sm uppercase 
                                 font-normal text-sm max-lg:text-xs ">
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(category.description, 100) }} />
                                </p>
                            </div>
                        );
                    })}

                {totalPages > 1 && (
                    <div className="pagination flex justify-center items-center gap-4">
                        <button
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Назад
                        </button>
                        <div className="flex items-center gap-2">{renderPageButtons()}</div>
                        <button
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Вперед
                        </button>
                    </div>
                )}


            </div>
        </div>
    );
};

export default Blog;
