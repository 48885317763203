
import { API_URL } from "../../constants";

interface ServiceCategory {
  id: number;
  name: string;
  description?: string;
  preview?: string;
  image?: string;
  meta_title: string;
  meta_h1: string;
  meta_description: string;
  meta_keywords?: string | null;
  seo_keyword: string;
}
interface ServicesProps {
  serviceApiData: ServiceCategory[];
}
const Services = ({ serviceApiData }: ServicesProps) => {

  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-black font-georgia text-[30px] font-bold mb-5 max-xl:text-xl max-lg:text-lg max-lg:mb-2 ">
        Наши услуги
      </h1>
      <p className="text-black font-georgia font-normal text-center text-2xl max-xl:text-xl max-lg:text-base  max-[425px]:text-sm">
        и основные направления юридической деятельности
      </p>
      <div className="grid grid-cols-6 mt-12 gap-16 max-[1700px]:gap-4 max-2xl:gap-2  max-lg:gap-10  max-lg:grid-cols-2 max-lg:mx-10 max-md:grid-cols-1 max-md:gap-4 ">
        {serviceApiData.map((category: ServiceCategory) => (
          <a
            key={category.name}
            href={`/${category.seo_keyword}`}
            className="flex flex-1 flex-col items-center justify-center  animation-hover
            w-[250px] h-[300px] rounded-[20px] border-2 transition-transform hover:scale-110
          border-white border-opacity-91 cursor-pointer
          max-2xl:w-[220px] max-2xl:h-[250px] max-[1400px]:w-[200px] max-xl:w-[160px] max-xl:h-[200px]  max-lg:h-[150px]  max-md:h-[150px]
          max-lg:flex-row max-lg:w-full max-lg:justify-start max-lg:px-5 max-[400px]:flex-col  max-[400px]:h-[180px]"
            style={{
              border: '2.018px solid rgba(255, 255, 255, 0.91)',
              background: 'linear-gradient(112deg, rgba(161, 237, 226, 0.30) -77.81%, rgba(217, 217, 217, 0.06) 101.02%)',
              boxShadow: '16.14769px 20.18461px 30.27692px 0px rgba(0, 0, 0, 0.10)',
            }}
          >
            {category.image ? (
              <img
                src={`${API_URL}/${category.image.replace("public", "storage")}`}
                alt={category.name}
                className="max-2xl:w-[150px] max-[1400px]:w-[120px]
                 max-xl:w-[100px] max-lg:mt-2 max-lg:mr-5 max-lg:mb-2 max-[400px]:mr-0"
              />
            ) : (
              <div
                className="flex items-center 
                justify-center w-[150px] h-[150px] max-2xl:w-[120px] 
                max-xl:w-[100px] max-lg:mt-2 max-lg:mr-5 max-lg:mb-2 max-[400px]:mr-0"
                style={{
                  background: '#fff',
                  border: '5px solid #2ba885',
                  borderRadius: '50%',
                }}
              ></div>
            )}
            <h2 className="text-black text-center 
             font-georgia text-lg font-bold mt-10 max-lg:mt-5 max-2xl:text-base ">
              {category.name}
            </h2>
          </a>
        ))}

      </div>
      <hr className="mt-28 w-full border-t h-[1px] border-[#1B877A] " />
    </div>
  )
}

export default Services