import { Link } from "react-router-dom";
import { navLinks } from "../constants";

interface NavLink {
  href: string;
  label: string;
}
interface BlogCategory {
  id: number;
  name: string;
  description: string | null;
  preview: string | null;
  image: string | null;
  sort_order: number;
  status: number;
  meta_title: string | null;
  meta_h1: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  seo_keyword: string;
  created_at: string;
  updated_at: string;
}
interface ServiceCategory {
  id: number;
  name: string;
  description?: string;
  preview?: string;
  image?: string;
  meta_title: string;
  meta_h1: string;
  meta_description: string;
  meta_keywords?: string | null;
  seo_keyword: string;
}
type NavMobileProps = {
  isOpen: boolean;
  filteredNavLinks: NavLink[];
  onClose: () => void;
  isServicesDropdownOpen: boolean;
  setIsServicesDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isBlogDropdownOpen: boolean;
  setIsBlogDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  serviceApiData: ServiceCategory[];
  blogApiData: BlogCategory[];
  phoneContent: string | null;
  addressContent: string | null;
  linkContent: string;
};

const NavMobile = ({ isOpen, filteredNavLinks, onClose, isServicesDropdownOpen, setIsBlogDropdownOpen, isBlogDropdownOpen,
  setIsServicesDropdownOpen, serviceApiData, blogApiData, phoneContent, addressContent, linkContent }: NavMobileProps) => {
  return (
    <div className={`max-lg:block ${isOpen ? 'fade-in' : ''}`}>
      <div className="flex flex-col items-center  justify-center gap-5 mt-5 list-none">
        {filteredNavLinks.map((item) => (
          <div key={item.label}>
            {item.href.startsWith('/') ? (
              <Link
                to={item.href}
                className="font-georgia cursor-pointer font-bold text-base text-white hover:text-hover active:text-hover animation-hover0"
                onClick={onClose}
              >
                {item.label}
              </Link>
            ) : item.label === "Услуги" ? (
              <div
                className="flex items-center flex-col  justify-center
                              font-georgia cursor-pointer font-bold text-base text-white hover:text-hover active:text-hover animation-hover"
                onClick={() => setIsServicesDropdownOpen(!isServicesDropdownOpen)}
              >
                {item.label}
                {isServicesDropdownOpen && (
                  <div className="flex flex-col items-center justify-center mt-2">
                    {serviceApiData.map((category: any) => (
                      <Link
                        key={category.name}
                        to={`/${category.seo_keyword}`}
                        className="py-2 px-4 font-georgia cursor-pointer 
                                             font-bold text-base text-white hover:text-hover active:text-hover animation-hover"
                        onClick={onClose}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ) : item.label === "Блог" ? (
              <div
                className="flex items-center flex-col  justify-center
                              font-georgia cursor-pointer font-bold text-base text-white hover:text-hover active:text-hover animation-hover"
                onClick={() => setIsBlogDropdownOpen(!isBlogDropdownOpen)}
              >
                {item.label}
                {isBlogDropdownOpen && (
                  <div className="flex flex-col items-center justify-center mt-2">
                    {blogApiData.map((category: BlogCategory) => (
                      <Link
                        key={category.name}
                        to={`/blog/${category.seo_keyword}`}
                        className="py-2 px-4 font-georgia cursor-pointer 
                                             font-bold text-base text-white hover:text-hover active:text-hover animation-hover"
                        onClick={onClose}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <a
                href={item.href}
                onClick={(e) => {
                  e.preventDefault();
                  const targetElement = document.getElementById(item.href.substring(1));
                  if (targetElement) {
                    window.scrollTo({
                      top: targetElement.offsetTop - 100,
                      behavior: 'smooth',
                    });
                  }
                  onClose();
                }}
                className="font-georgia cursor-pointer font-bold text-base text-white hover:text-hover active:text-hover animation-hover"
              >
                {item.label}
              </a>
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center mt-5">
        <a
          href={linkContent}  target="_blank"
          className="text-white text-sm  font-normal font-georgia mb-2 hover:text-hover animation-hover"
        >
          {addressContent}
        </a>
        {/* <a
          href={`tel:${phoneContent}`}
          className="text-white text-sm font-georgia font-normal hover:text-hover animation-hover"
        >
          {phoneContent}
        </a> */}
      </div>
    </div>
  );
};

export default NavMobile;
