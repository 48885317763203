import axios from "axios";
import { API_URL } from "../constants";

export const fetchMainData = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/main`);
    if (response.status === 200 && response.data.message === 'Данные успешно найдены') {
      return response.data.data;
    } else {
      throw new Error('Данные не найдены');
    }
  } catch (error) {
    throw new Error(`Ошибка запроса`);
  }
};

export const fetchInfoData = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/information`);
    if (response.status === 200 && response.data.message === 'Данные успешно найдены') {
      return response.data.data;
    } else {
      throw new Error('Данные не найдены');
    }
  } catch (error) {
    throw new Error(`Ошибка запроса`);
  }
};

export const fetchServiceCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/service/category`);
    return response.data.data.data;
  } catch (error) {
    console.error('Ошибка запроса (Услуги):', error);
    throw error; 
  }
};

export const fetchBlogCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/blog/category`);
    return response.data.data.data;
  } catch (error) {
    console.error('Ошибка запроса (Блог):', error);
    throw error; 
  }
};

export const fetchBlogArticleCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/blog/category/show/stati`);
    return response.data.data.articles.data;
  } catch (error) {
    console.error('Ошибка запроса (Блог):', error);
    throw error; 
  }
};

export const fetchBlogArticleSeo = async (seoKeyword:string) => {
  try {
    const response = await axios.get(`${API_URL}/api/blog/article/show/${seoKeyword}`);
    return response.data;
  } catch (error) {
    console.error('Ошибка запроса (Блог):', error);
    throw error; 
  }
};

export const fetchReviewData = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/review`);
    if (response.status === 200 && response.data.message === 'Данные успешно найдены') {
      return response.data.data.data;
    } else {
      throw new Error('Данные не найдены');
    }
  } catch (error) {
    throw new Error(`Ошибка запроса`);
  }
};


export const fetchCategoryData = async (seoKeyword:string) => {
  try {
    const response = await axios.get(`${API_URL}/api/service/category/show/${seoKeyword}`);
    if (response.data.message === 'Данные успешно найдены') {
      return response.data.data;

    } else {
      console.error('Ошибка: Данные не найдены');
      return [];
    }
  } catch (error) {
    console.error('Ошибка запроса:', error);
    throw error;
  }
};
