import { Return } from "../../assets";

interface BlogArticleDetailsProps {
    headerContent: string;
    descriptionContent: string;
}
const BlogArticleDetails = ({ headerContent, descriptionContent }: BlogArticleDetailsProps) => {
    
    return (
        <div className="flex flex-col justify-center items-center ">
            <div className="w-full bg-[#CEE1DE] mt-10 ">
                <h1 className="p-8 text-black font-georgia text-[30px]
                text-center font-bold max-2xl:text-2xl  max-xl:text-xl max-lg:text-lg  max-lg:mb-2">
                    {headerContent}
                </h1>
            </div>
            <div className="mx-80 mt-10 border-2 border-turquoise p-10 w-[70%] max-2xl:w-[90%]  max-md:p-5">
                <div className="text-black font-georgia" dangerouslySetInnerHTML={{ __html: descriptionContent }} />
            </div>
            <div className=" flex justify-center my-10">
                <a href="/blog/stati" className="text-black font-georgia font-bold hover:text-turquoise transition-opacity duration-150 ease delay-100 " style={{ display: "flex", alignItems: "center" }}>
                    Вернуться к списку статей
                    <img src={Return} alt="return" className="w-5 h-5 ml-2" />
                </a>
            </div>

        </div>
    )
}

export default BlogArticleDetails