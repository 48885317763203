import { createContext, useContext, useEffect, useState } from 'react';
import { fetchBlogCategories, fetchMainData, fetchServiceCategories } from '.';

const ApiContext = createContext();


export const ApiProvider = ({ children }) => {
  
  const [serviceApiData, setServiceApiData] = useState([]);
  const [blogApiData, setBlogApiData] = useState([]);
  const [phoneContent, setPhoneContent] = useState('');
  const [addressContent, setAddressContent] = useState('');
  const [linkContent, setLinkContent] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [vkContent, setVkContent] = useState('');
  const [tgContent, setTgContent] = useState('');
  const [waContent, setWaContent] = useState('');
  const [timeContent, setTimeContent] = useState('');
  const [infoContent, setInfoContent] = useState('');
  const [politicContent, setPoliticContent] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [mapContent, setMapContent] = useState('');


  const fetchData = async () => {
    try {
      const mainData = await fetchMainData();
      const servicedData = await fetchServiceCategories();
      const blogData = await fetchBlogCategories();


      setMetaTitle(mainData.meta_title);
      setMetaDescription(mainData.meta_description);
      setPhoneContent(mainData.phone);
      setAddressContent(mainData.short_address);
      setLinkContent(mainData.map_link);
      setPoliticContent(mainData.politic);
      setEmailContent(mainData.email);
      setVkContent(mainData.vk);
      setTgContent(mainData.telegram);
      setWaContent(mainData.whatsapp);
      setTimeContent(mainData.time);
      setInfoContent(mainData.additional_info);
      setMapContent(mainData.map);
      
      setServiceApiData(servicedData);
      setBlogApiData(blogData);
    }
    catch (error) {
      if (error.response && error.response.status === 429) {
        console.log('Rate limited. Retrying after 1 second...');
        setTimeout(() => fetchData(), 1000);
      } else {
        console.error('Ошибка запроса:', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const contextValue = {
    serviceApiData,
    blogApiData,
    phoneContent,
    addressContent,
    linkContent,
    emailContent,
    vkContent,
    tgContent,
    waContent,
    timeContent,
    infoContent,
    metaTitle,
    metaDescription,
    politicContent,
    mapContent
  };

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('Ошибка');
  }

  return context;
};
