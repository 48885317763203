import { Galka, QuestionIcon } from "../../assets";

interface QuestionProps {
  descriptionContent: string;
}

const Question: React.FC<QuestionProps> = ({ descriptionContent }) => (
  <>
    {descriptionContent && (
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-black font-georgia text-[30px] text-center font-bold mb-10 max-xl:text-xl max-lg:text-lg  max-lg:mb-5  max-lg:px-2 ">
          Наши специалисты помогут Вам с разрешением <br />
          следующих вопросов:
        </h1>
        <div className='flex items-center mt-2 '>
          <img src={QuestionIcon} alt='Вопрос' className='w-[30%] h-[30%]  mr-10  max-lg:hidden' />
          <div className='flex flex-col items-center '>
            {descriptionContent.split('\n').slice(1, -1).map((item, i) => (
              <div key={i} className="flex items-center mb-5 w-[800px] max-lg:w-full   max-lg:px-2">
                <img src={Galka} alt='галка'  className=" max-lg:pl-5" />
                <div className='list-none text-black font-georgia text-[20px] font-normal leading-[25px] text-justify  max-xl:pr-10 max-lg:text-left 
                max-lg:text-base max-lg:leading-[30px]'
                  dangerouslySetInnerHTML={{ __html: item || "" }} />
              </div>
            ))}
          </div>
        </div>
        <hr className="mt-12 w-full border-t h-[1px] border-[#1B877A]" />
      </div>
    )}
  </>
);

export default Question;
