import { Link, useLocation } from 'react-router-dom';
import React, { useState } from 'react';

import NavMobile from './NavMobile';
import { hamburger } from '../assets';
import { navLinks } from '../constants';
import { useApi } from '../api/ApiProvider';

interface NavLink {
  href: string;
  label: string;
}

interface ServiceCategory {
  id: number;
  name: string;
  description?: string;
  preview?: string;
  image?: string;
  meta_title: string;
  meta_h1: string;
  meta_description: string;
  meta_keywords?: string | null;
  seo_keyword: string;
}

interface BlogCategory {
  id: number;
  name: string;
  description: string | null;
  preview: string | null;
  image: string | null;
  sort_order: number;
  status: number;
  meta_title: string | null;
  meta_h1: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  seo_keyword: string;
  created_at: string;
  updated_at: string;
}

const Nav = () => {
  const location = useLocation();
  const { serviceApiData, blogApiData, phoneContent, addressContent, linkContent } = useApi();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isBlogDropdownOpen, setIsBlogDropdownOpen] = useState(false);
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const [isBlogHovered, setIsBlogHovered] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavHover = (label: string, isHovered: boolean) => {
    if (label === 'Услуги') {
      setIsServicesHovered(isHovered);
      setIsBlogHovered(false);
    } else if (label === 'Блог') {
      setIsBlogHovered(isHovered);
      setIsServicesHovered(false);
    }
  };

  const onClose = () => {
    setIsMenuOpen(false);
    setIsServicesDropdownOpen(false);
    setIsBlogDropdownOpen(false);
    setIsServicesHovered(false);
    setIsBlogHovered(false);
    
  };

  const filteredNavLinks: NavLink[] = navLinks.filter(link => {
    if (
      (location.pathname === '/about' || location.pathname === '/price') &&
      link.href === '#reviews'
    ) {
      return false;
    }

    if (
      (location.pathname.startsWith('/articles/') || location.pathname === '/blog/stati') &&
      (link.href === '#reviews' || link.href === '#contact')
    ) {
      return false;
    }

    return true;
  });

  const filteredWithoutBlog = filteredNavLinks.filter((link) => link.label !== "Блог")

  return (
    <section className="w-full flex flex-col py-6 px-10 bg-turquoise items-center justify-between lg:flex-row">
      <div className="flex max-lg:justify-between max-lg:w-full max-lg:items-center ">
        <a href="/" className="text-white uppercase font-bold font-georgia text-4xl cursor-pointer max-xl:text-2xl  max-[500px]:text-xl 
        hover:text-hover animation-hover">Мера права</a>
        <button onClick={toggleMenu} className="text-white text-lg font-georgia cursor-pointer ml-10 hidden max-lg:block ">
          {isMenuOpen ? '✕' : <img src={hamburger} alt="hamburger icon" width={30} height={30} className="cursor-pointer" />}
        </button>
      </div>
      <div className="flex gap-10 max-xl:gap-4 max-lg:gap-8  max-lg:hidden items-center">
        {filteredWithoutBlog.map((item) => (

          <div
            key={item.label}
            onMouseEnter={() => handleNavHover(item.label, true)}
            onMouseLeave={() => handleNavHover(item.label, false)}
          >
            {item.label === 'Услуги' ? (
              <div
                className="font-georgia cursor-pointer font-bold
                 text-base text-white hover:text-hover active:text-hover animation-hover"
                onClick={() => setIsServicesDropdownOpen(!isServicesDropdownOpen)}
              >
                {item.label}
                {isServicesHovered && (
                  <div className="absolute shadow-md  z-10 bg-turquoise rounded-xl">
                    {serviceApiData.map((category: ServiceCategory) => (
                      <Link
                        key={category.name}
                        to={`/${category.seo_keyword}`}
                        className="block py-2 px-4 font-georgia cursor-pointer font-bold text-base text-white hover:text-hover active:text-hover animation-hover"
                        onClick={onClose}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ) 
            : (
              item.label === 'Блог' ? (
                <div
                  className="font-georgia cursor-pointer font-bold
                    text-base  text-white hover:text-hover active:text-hover animation-hover"

                  onClick={() => setIsBlogDropdownOpen(!isBlogDropdownOpen)}
                >
                  {item.label}
                  {isBlogHovered && (
                    <div className="absolute shadow-md  z-10 bg-turquoise rounded-xl">
                      {blogApiData.map((category: BlogCategory) => (
                        <Link
                          key={category.name}
                          to={`/blog/${category.seo_keyword}`}
                          className="block py-2 px-4 font-georgia cursor-pointer font-bold text-base text-white hover:text-hover active:text-hover animation-hover"
                          onClick={onClose}
                        >
                          {category.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                item.href.startsWith('/') ? (
                  <Link
                    to={item.href}
                    className="font-georgia cursor-pointer font-bold text-base  text-white hover:text-hover active:text-hover animation-hover"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <a
                    href={item.href}
                    onClick={(e) => {
                      e.preventDefault();
                      const targetElement = document.getElementById(item.href.substring(1));
                      if (targetElement) {
                        window.scrollTo({
                          top: targetElement.offsetTop - 100,
                          behavior: 'smooth',
                        });
                      }
                    }}
                    className="font-georgia cursor-pointer font-bold text-base text-white hover:text-hover active:text-hover animation-hover"
                  >
                    {item.label}
                  </a>
                )
              )
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-col  items-center justify-center max-lg:hidden">
        <a
          href={linkContent}  target="_blank"
          className="text-white text-lg  max-xl:text-sm font-normal font-georgia  hover:text-hover animation-hover"
        >
          {addressContent}
        </a>
        {/* <a
          href={`tel:${phoneContent}`}
          className="text-white text-lg max-xl:text-sm font-georgia font-normal hover:text-hover animation-hover"
        >
          {phoneContent}
        </a> */}
      </div>
      {isMenuOpen && <NavMobile isOpen={isMenuOpen} filteredNavLinks={filteredWithoutBlog} onClose={onClose}
        isServicesDropdownOpen={isServicesDropdownOpen} setIsServicesDropdownOpen={setIsServicesDropdownOpen}
        isBlogDropdownOpen={isBlogDropdownOpen} setIsBlogDropdownOpen={setIsBlogDropdownOpen} serviceApiData={serviceApiData}
        blogApiData={blogApiData} phoneContent={phoneContent} addressContent={addressContent} linkContent={linkContent} />}
    </section>
  );
};

export default Nav;
