import { useEffect, useState } from "react";
import { fetchBlogArticleSeo } from "../../api";
import { Helmet } from "react-helmet";
import { ContactAndScroll } from "../../sections";
import BlogArticleDetails from "./BlogArticleDetails";


interface BlogProps {
    seoKeyword: string;
}

const BlogArticle = ({ seoKeyword }: BlogProps) => {
    const [metaTitle, setMetaTitle] = useState<string>('');
    const [metaDescription, setMetaDescription] = useState<string>('');
    const [headerContent, setHeaderContent] = useState<string>('');
    const [descriptionContent, setDescriptionContent] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categories = await fetchBlogArticleSeo(seoKeyword);
                setMetaTitle(categories.data.meta_title);
                setMetaDescription(categories.data.meta_description);
                setHeaderContent(categories.data.meta_h1);
                setDescriptionContent(categories.data.description);
            } catch (error) {
                console.error("Ошибка запроса:", error);
            }
        };

        fetchData();
    }, [seoKeyword]);

    return (
        <div>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
            </Helmet>
            <section>
                <ContactAndScroll />
            </section>
            <section>
               <BlogArticleDetails headerContent={headerContent} descriptionContent={descriptionContent}/> 
            </section>
           
        </div>
    );
};

export default BlogArticle;
