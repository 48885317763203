import React from 'react'

type Props = {
  header: string;
  text: string;
}

const Advantage: React.FC<Props> = ({ header, text }) => {
  return (
    <div className='w-[300px] h-[220px] p-8 flex flex-col items-center justify-center rounded-2xl border border-gray-200 font-georgia'>
      <h3 className='uppercase font-bold text-[#1B877A] text-3xl'>{header}</h3>
      <p className='text-black text-md text-center'>
        {text}
      </p>
    </div>
  )
} 

const Advantages = () => {
  const advatages = [
    {
      header: "1 млрд +",
      text: "Cумма сохраненного имущества клиентов"
    },    
    {
      header: ">90 %",
      text: "Cудебных дел выигранно в пользу наших клиентов"
    },
    {
      header: ">30",
      text: "Городов, где мы успешно представляем интересы в судах"
    },
    {
      header: ">80 %",
      text: "Споров решены нашими юристами путем мирных переговоров"
    },
  ]

  return (
    <div className='w-full h-max min-h-[50dvh] flex flex-wrap items-center justify-center gap-6'>
      {advatages.map((advantage) => (
        <Advantage header={advantage.header} text={advantage.text} key={advantage.header} />
      ))}
    </div>
  )
}

export default Advantages