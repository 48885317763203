
import { aboutBannerAdaptive, aboutCompany } from "../../assets";

interface MainScreenProps {
  headerContent: string;
}
const MainScreen = ({ headerContent }: MainScreenProps) => {
  return (
    <div className="relative flex items-center justify-center ">
       <img
        src={aboutCompany}
        alt="banner"
        className="max-sm:hidden block object-cover"
      />

      <img
        src={aboutBannerAdaptive}
        alt="adaptive-banner"
        className="max-sm:block hidden w-full h-auto object-cover "
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  text-white text-center ">
        <div
          className="flex flex-col justify-center items-center rounded-[20px]  shadow-md 
          w-[700px] h-[300px] max-[1440px]:w-[600px]  max-xl:w-[550px] max-xl:h-[250px]  max-lg:w-[500px] max-lg:h-[200px] 
          max-md:w-[400px]  max-md:h-[180px]  max-[425px]:w-[300px] max-[425px]:h-[150px] 
     "
          style={{
            borderRadius: "20px",
            background: "rgba(255, 255, 255, 0.10)",
            backdropFilter: "blur(2.700000047683716px)",
            WebkitBackdropFilter: "blur(2.700000047683716px)",
            boxShadow: "16.14769px 20.18461px 30.27692px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <h1 className="text-center text-[#0F0D0D] font-georgia leading-snug mt-5 text-[42px] font-bold mb-10
           max-[1440px]:text-[38px] max-[1440px]:mb-8  max-xl:text-4xl max-lg:text-3xl  px-16
            max-md:text-2xl max-md:mb-8 max-[425px]:text-xl  max-[425px]:mb-3">
            {headerContent}
          </h1>
        </div>

      </div>
    </div>

  );
};

export default MainScreen;
