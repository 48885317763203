
import CategoryTable from './CategoryTable';

interface Service {
  id: number;
  name: string;
  price: string;
  description: string | null;
  preview: string | null;
}


interface ServiceCategory {
  id: number;
  name: string;
  description?: string;
  preview?: string;
  image?: string;
  meta_title: string;
  meta_h1: string;
  meta_description: string;
  meta_keywords?: string | null;
  seo_keyword: string;
  services: Service[];
}

interface DynamicTableProps {
  categories: ServiceCategory[];
  activeCategory: ServiceCategory | null;
  onCategoryClick: (category: ServiceCategory) => void;
}


const DynamicTable = ({ categories, activeCategory, onCategoryClick }: DynamicTableProps) => {

  return (
    <div>
      <div className="flex justify-around items-center my-4 max-lg:hidden">
        {categories.map((category) => (
          <button
            key={category.name}
            onClick={() => onCategoryClick(category)}
            className={`px-4 py-2 text-center ${activeCategory && activeCategory.name === category.name
              ? 'text-lg line-height-[44.28px] font-georgia font-bold border-b-4 border-[#1B877A] text-[#0F5048]'
              : 'text-black text-lg bg-transparent line-height-[44.28px] font-georgia font-semibold' 
              } rounded animation-hover`}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className="max-lg:flex mt-10 mb-2 hidden flex-col mx-2">
        <label className="font-georgia font-semibold bg-white text-black mb-2">Выберите таблицу:</label>
        <select
          value={activeCategory?.name || ''}
          onChange={(e) => onCategoryClick(categories.find((category) => category.name === e.target.value) ?? categories[0])}
          className="border border-[#1B877A] text-sm rounded-lg focus:ring-darkgreen 
           focus:border-darkgreen block w-full p-4 font-georgia font-semibold bg-white shadow-md hover:shadow-lg text-black"
          style={{ transition: 'box-shadow 0.3s' }}
        >
          {categories.map((category) => (
            <option key={category.name} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="overflow-x-auto mx-20 max-lg:mx-2">
        <div className="animation-hover">
          {activeCategory && (
            <CategoryTable seoKeyword={activeCategory.seo_keyword} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicTable;
