import { Advantages, Consultation, Contact, MainScreen, Services, Stage } from "../components/home"
import { fetchInfoData, fetchReviewData } from "../api";
import { useEffect, useState } from "react"

import Apperal from "../components/home/Apperal";
import { ContactAndScroll } from "../sections";
import { Helmet } from 'react-helmet';
import Reviews from "../components/home/Reviews"
import { useApi } from "../api/ApiProvider";

interface Category {
  id: number;
  category_id: number | null;
  name: string;
  description: string | null;
  preview: string | null;
  image: string | null;
  sort_order: number;
  status: number;
  meta_title: string | null;
  meta_h1: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  seo_keyword: string | null;
  created_at: string;
  updated_at: string;
}
interface Review {
  id: number;
  category_id: number;
  author: string;
  text: string;
  sort_order: number;
  status: number;
  date_available: string;
  created_at: string;
  updated_at: string;
  category: Category;
}

const Home = () => {
  const [descriptionAboutContent, setDescriptionAboutContent] = useState<string>('');
  const [reviewData, setReviewData] = useState<Review[]>([]);
  const { metaTitle, metaDescription, serviceApiData, } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const aboutData = await fetchInfoData();
        setDescriptionAboutContent(aboutData.data[1].description);
        const reviewsData = await fetchReviewData();
        setReviewData(reviewsData);
      } catch (error) {
        console.error('Ошибка запроса:', error);
      }
    };
    fetchData();

  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <section>
        <ContactAndScroll />
      </section>
      <section className="pt-10 max-lg:pt-8">
        <MainScreen />
      </section>
      <section className="py-10 max-lg:pt-8 border border-transparent border-b-[#1B877A]">
        <Advantages />
      </section>
      <section className="pt-14 max-lg:pt-10">
        <Services serviceApiData={serviceApiData} />
      </section>
      <div dangerouslySetInnerHTML={{ __html: descriptionAboutContent || "" }} />
      <section className="pt-5">
        <Stage />
      </section>
      <section className="pt-16">
        <Consultation serviceApiData={serviceApiData} />
      </section>
      <section className="pt-8">
        <Reviews reviewData={reviewData} />
      </section>
      <section className="pt-7">
        <Contact />
      </section>
    </div >
  )
}

export default Home