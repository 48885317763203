import { ThreeDots, dots } from "../../assets"
import { stagesWork } from "../../constants"

const Stage = () => {
    return (
        <div className="flex flex-col justify-center items-center ">
            <h1 className="text-black font-georgia text-[30px] font-bold mb-5 max-xl:text-xl max-lg:text-lg max-lg:mb-2">
                Этапы работы
            </h1>
            <div className="flex mt-20  px-[220px]  max-[1600px]:px-[50px] max-xl:flex-col max-xl:mt-10" >
                {stagesWork.map((stage) => (
                    <div className="flex justify-center items-center max-xl:flex-col" key={stage.id}>
                        <div className="flex flex-col justify-center items-center max-xl:mb-8 ">
                            <img
                                src={stage.image}
                                alt="banner"
                            />
                            <h2 className="text-black text-center 
                        font-georgia text-base font-bold mt-5 ">
                                {stage.title}
                            </h2>
                            <p className="text-black text-center 
                        font-georgia text-sm font-normal mt-5">
                                {stage.description}
                            </p>
                        </div>
                        {
                            stage.dots ?
                                <div>
                                    <img
                                        src={dots}
                                        alt="banner"
                                        className="mb-20 max-xl:rotate-90 block max-xl:hidden "
                                    />
                                    <img
                                        src={ThreeDots}
                                        alt="banner"
                                        className="mb-10 max-xl:rotate-90 hidden max-xl:block  "
                                    />
                                </div>
                                : ''}

                    </div>
                ))}
            </div>
            <hr className="mt-28   max-xl:mt-5 w-full border-t h-[1px] border-[#1B877A] " />
        </div>
    )
}

export default Stage