import { Consultation, Contact } from "../components/home"
import { DynamicTable, MainScreen } from "../components/price"
import { useEffect, useState } from "react"
import { fetchCategoryData, fetchServiceCategories } from "../api";
import { ContactAndScroll } from "../sections";
import { useApi } from "../api/ApiProvider";

interface Service {
    id: number;
    name: string;
    price: string;
    description: string | null;
    preview: string | null;
}


interface ServiceCategory {
    id: number;
    name: string;
    description?: string;
    preview?: string;
    image?: string;
    meta_title: string;
    meta_h1: string;
    meta_description: string;
    meta_keywords?: string | null;
    seo_keyword: string;
    services: Service[];
}
const Price = () => {
    const { serviceApiData } = useApi();
    const [categories, setCategories] = useState<ServiceCategory[]>([]);
    const [activeCategory, setActiveCategory] = useState<ServiceCategory | null>(null);

    useEffect(() => {
        const fetchCategoriesData = async () => {
            try {
                const data = await fetchServiceCategories();
                setCategories(data);
                setActiveCategory(data[0]);
                
            } catch (error) {
                console.error('Ошибка запроса (Категории услуг):', error);
            }
        };

        fetchCategoriesData();
    }, []);

    const handleCategoryClick = async (category: ServiceCategory) => {
        setActiveCategory(category);
    };


    return (
        <div>
            <section>
                <ContactAndScroll />
            </section>
            <section className="pt-10 max-lg:pt-8 ">
                <MainScreen />
            </section>
            <section>
                <DynamicTable
                    categories={categories}
                    activeCategory={activeCategory}
                    onCategoryClick={handleCategoryClick}
                />
            </section>
            <section className="pt-16">
                <Consultation serviceApiData={serviceApiData} />
            </section>
            <section className="pt-7">
                <Contact />
            </section>
        </div>
    );
}

export default Price