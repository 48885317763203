import { useState, useEffect, SetStateAction } from "react";
import { arrowLeft, arrowRight } from '../../assets';
import { AddReviews } from "../../sections/Home";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Category {
    id: number;
    category_id: number | null;
    name: string;
    description: string | null;
    preview: string | null;
    image: string | null;
    sort_order: number;
    status: number;
    meta_title: string | null;
    meta_h1: string | null;
    meta_description: string | null;
    meta_keywords: string | null;
    seo_keyword: string | null;
    created_at: string;
    updated_at: string;
}
interface Review {
    id: number;
    category_id: number;
    author: string;
    text: string;
    sort_order: number;
    status: number;
    date_available: string;
    created_at: string;
    updated_at: string;
    category: Category;
}

interface ReviewsProps {
    reviewData: Review[];
}
const Reviews = ({ reviewData }: ReviewsProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleItems, setVisibleItems] = useState(6);
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const maxLength = 500;
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);
    const [isFormOpen, setIsFormOpen] = useState(false);

    const toggleForm = () => {
        setIsFormOpen(!isFormOpen);
    };

    const toggleExpand = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    const PrevArrow = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + reviewData.length) % reviewData.length);
        setExpandedIndex(-1);
    };

    const NextArrow = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviewData.length);
        setExpandedIndex(-1);
    };

    useEffect(() => {
        const handleCarouselWraparound = () => {
            if (currentIndex < 0) {
                setCurrentIndex(reviewData.length - 1);
            } else if (currentIndex >= reviewData.length) {
                setCurrentIndex(0);
            }
        };

        handleCarouselWraparound();
    }, [currentIndex, reviewData]);

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;

            if (windowWidth < 1024) {
                setVisibleItems(1);
            } else if (reviewData.length < 4) {
                setVisibleItems(reviewData.length);
            } else {
                if (windowWidth < 1440) {
                    setVisibleItems(2);
                } else if (windowWidth < 1700) {
                    setVisibleItems(3);
                } else if (windowWidth < 1921) {
                    setVisibleItems(4);
                } else {
                    setVisibleItems(6);
                }
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [reviewData]);



    const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (e) => {
        setTouchEndX(e.touches[0].clientX);
    };

    const handleTouchEnd: React.TouchEventHandler<HTMLDivElement> = () => {
        const touchDiff = touchEndX - touchStartX;

        if (touchDiff > 50) {
            PrevArrow();
        } else if (touchDiff < -50) {
            NextArrow();
        }
    };

    return (
        <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center" id="reviews">
                <h1 className="text-black font-georgia text-[30px] font-bold mb-5 max-xl:text-xl max-lg:text-lg max-lg:mb-2 ">
                    Отзывы
                </h1>
            </div>
            <div className="relative overflow-hidden"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}>
                <div className="flex justify-center items-center px-2">
                    {(reviewData.concat(reviewData)).slice(currentIndex, currentIndex + visibleItems).map((review, index) => (
                        <div key={index} className={`w-full p-2 sm:p-4 ${(visibleItems === 1 || visibleItems === 2 || (visibleItems === 3 && index === 1) ||
                            (visibleItems === 4 && (index === 1 || index === 2)) ||
                            (visibleItems === 6 && (index === 1 || index === 2 || index === 3 || index === 4))) ? '' : 'blur-effect'
                            }`}>
                            <div className="flex flex-col items-center rounded-lg border border-solid border-turquoise bg-white">
                                <div className="text-black text-justify font-georgia font-xs font-normal p-8 min-h-[400px]"
                                    style={{ maxHeight: expandedIndex === index ? 'none' : '700px', overflow: 'hidden' }}>
                                    {review.text.length > maxLength ? (
                                        <div className="flex flex-col">
                                            {expandedIndex === index ? review.text : `${review.text.slice(0, maxLength)}...`}
                                            <button onClick={() => toggleExpand(index)} className="text-turquoise cursor-pointer">
                                                {expandedIndex === index ? 'Скрыть' : 'Подробнее'}
                                            </button>
                                        </div>
                                    ) : (
                                        review.text
                                    )}
                                </div>
                                <div className="flex flex-col justify-center items-center mt-4 bg-turquoise w-full rounded-bl-lg rounded-br-lg p-5">
                                    <p className="text-white text-center font-georgia font-semibold text-sm mb-2">{review.author}</p>
                                    <p className="text-white text-center font-georgia font-normal text-xs mb-2">Тип обращения:  {review.category.name}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {reviewData.length > 1 && (
                    <>
                        <button
                            onClick={PrevArrow}
                            className="absolute z-10 top-1/2 left-[15%] cursor-pointer transform -translate-y-1/2 px-4 py-2 transition duration-600 ease border-none
         max-[1440px]:left-[5%] max-md:left-[-5%]"
                        >
                            <img src={arrowLeft} alt="left" />
                        </button>
                        <button
                            onClick={NextArrow}
                            className="absolute z-10 top-1/2 right-[15%] cursor-pointer transform -translate-y-1/2 px-4 py-2 transition duration-600 ease border-none
        max-[1440px]:right-[5%] max-md:right-[-5%]"
                        >
                            <img src={arrowRight} alt="right" />
                        </button>
                    </>
                )}
            </div>

            <button className="animation-hover bg-turquoise hover:bg-darkgreen shadow-md mt-5
                            rounded-[10px] w-[250px] h-[50px] text-white font-georgia
                            text-xl max-[1440px]:text-base max-[1440px]:w-[200px]  max-md:w-[150px] max-md:text-xs  font-bold  uppercase" onClick={toggleForm}>Оставить отзыв</button>
            {isFormOpen && <AddReviews toggleForm={toggleForm} />}
            <hr className="mt-12 w-full border-t h-[1px] border-[#1B877A]" />
        </div>
    );
};

export default Reviews;