import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { arrowDown } from "../../assets";
import axios from "axios";
import { API_URL } from "../../constants";
import { useEffect, useState } from "react";

type AddReviewsProps = {
    toggleForm: () => void;
}
interface ServiceCategory {
    id: number;
    name: string;
    description?: string;
    preview?: string;
    image?: string;
    meta_title: string;
    meta_h1: string;
    meta_description: string;
    meta_keywords?: string | null;
    seo_keyword: string;
}



const AddReviews = ({ toggleForm }: AddReviewsProps) => {
    const [apiData, setApiData] = useState<ServiceCategory[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
    const {
        register,
        handleSubmit,
        reset,
    } = useForm()

    const addForm: SubmitHandler<FieldValues> = async (data) => {
        try {
            const response = await axios.post(
                `${API_URL}/api/review/store`,
                {
                    author: data.author,
                    category_id: parseInt(data.category_id, 10),
                    text: data.text,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                console.log("Данные успешно добавлены");
                console.log(response.data);
                reset();
                toggleForm();
            }
        } catch (error: any) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error("Ошибка запроса:", error);
            }
        }
    };

    const fetchServiceCategories = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/service/category`);
            const modifiedData = response.data.data.data.map((category: ServiceCategory, index: number) => ({
                ...category,
                id: index + 1,
            }));
            setApiData(modifiedData);
        } catch (error) {
            console.error('Ошибка запроса:', error);
        }
    };

    useEffect(() => {
        fetchServiceCategories();
    }, []);
    return (

        <div className="fixed w-full h-full inset-0 z-50 overflow-hidden animated fadeInPrivacy faster flex items-center justify-center" style={{ background: "rgba(0,0,0,.7)" }}>
            <div className="max-w-md  xl:max-w-5xl lg:max-w-5xl md:max-w-2xl mx-5 bg-turquoise max-h-screen shadow-lg flex-row rounded-xl relative">
                <div className="py-4 text-left px-6">
                    <div className="flex justify-between items-center pb-3">
                        <p className="text-2xl text-white font-georgia font-bold max-md:text-base">Ваш отзыв</p>
                        <div className="cursor-pointer z-50 font-bold text-md text-white " onClick={toggleForm}>
                            ✕
                        </div>
                    </div>
                    <div className="my-5 ">
                        <form onSubmit={handleSubmit(addForm)} >
                            <div className="flex items-center mt-10 justify-between mb-5 max-lg:mt-5 ">
                                <label className="w-[250px] max-md:w-[150px] max-[500px]:w-[120px] ">
                                    <span className="text-white font-georgia text-xl font-medium max-md:text-sm "> Имя </span>
                                </label>
                                <div className=" flex flex-col w-full ">
                                    <input type="text" className="flex-grow border-2 border-turquoise rounded-[10px] h-12 pl-2  font-georgia text-xl font-medium
                             max-[1440px]:text-base max-md:text-sm max-[500px]:h-10" placeholder="Введите имя"
                                        {...register("author")} />
                                    {errors.author && (
                                        <div className="text-red-400">{errors.author[0]}</div>
                                    )} </div>
                            </div>

                            <div className="flex items-center mt-10 justify-between mb-5 max-lg:mt-5 ">
                                <label className="w-[250px] max-md:w-[150px] max-[500px]:w-[120px] ">
                                    <span className="text-white font-georgia text-xl font-medium max-md:text-sm "> Отзыв </span>
                                </label>
                                <div className=" flex flex-col w-full ">
                                    <textarea
                                        className="flex-grow modal-content rounded-[10px] h-40 pl-2
                                     font-georgia text-xl font-medium max-[1440px]:text-base
                                      max-md:text-sm  max-h-52 pt-2"
                                        placeholder="Введите ваш отзыв"
                                        {...register("text")}
                                    />
                                    {errors.text && (
                                        <div className="text-red-400">{errors.text[0]}</div>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center mt-10  justify-between mb-5 relative  ">
                                <label className="w-[250px] max-md:w-[150px] max-[500px]:w-[120px]">
                                    <span className="text-white font-georgia text-xl font-medium max-md:text-sm mr-2"> Вид обращения</span>
                                </label>
                                <div className=" flex flex-col w-full justify-center">
                                <select
                                    className="flex-grow rounded-[10px] h-12 text-black font-georgia text-xl font-medium appearance-none px-4 py-2
                                    max-[1440px]:text-base max-md:text-xs max-md:px-1 max-[500px]:h-10"
                                    {...register("category_id")}>
                                    <option value="">Выберите вид обращения</option>
                                    {apiData.map((category: ServiceCategory) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}

                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mt-4 mb-4  max-[500px]:hidden ">
                                    <img src={arrowDown} alt="arrow" />
                                </div>
                                {errors.category_id && (
                                    <div className="text-red-400">{errors.category_id[0]}</div>
                                )}
                                 </div>
                            </div>
                            <div className="flex justify-center mt-10  max-[500px]:mt-5 ">
                                <button className="animation-hover hover:bg-slate-200 bg-white shadow-md 
                            rounded-[10px] w-[150px] h-[45px] text-[#202E40] font-georgia
                            text-xl max-[1440px]:text-base max-md:text-xs  font-bold  uppercase">отправить</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

        </div >
    )
}

export default AddReviews