
import { useEffect, useState } from "react";
import { fetchCategoryData } from "../../api";
import { useApi } from "../../api/ApiProvider";


interface Service {
  id: number;
  name: string;
  price: string;
  description: string | null;
  preview: string | null;
}


interface ServiceCategory {
  id: number;
  name: string;
  description?: string;
  preview?: string;
  image?: string;
  meta_title: string;
  meta_h1: string;
  meta_description: string;
  meta_keywords?: string | null;
  seo_keyword: string;
  services: Service[];
}

interface CategoryTableProps {
  seoKeyword: string;

}

const CategoryTable: React.FC<CategoryTableProps> = ({ seoKeyword }) => {
  const [serviceDataApi, setServiceDataApi] = useState<ServiceCategory[]>([]);
  const { phoneContent } = useApi();
  const fetchCategoryTableData = async () => {
    try {
      const data = await fetchCategoryData(seoKeyword);
      setServiceDataApi(data.categories);

    } catch (error) {
      console.error('Ошибка запроса', error);
    }
  };

  useEffect(() => {
    fetchCategoryTableData();
  }, [seoKeyword]);


  const uniqueServices: string[] = Array.from(new Set(serviceDataApi.flatMap(category => category.services.map(service => service.name))));

  const formatNumber = (number: string) => {
    const parsedNumber = typeof number === 'string' ? parseFloat(number) : number;
    return new Intl.NumberFormat('ru-RU').format(parsedNumber);
  };

  return (
    <div>
      {serviceDataApi.length === 0 ? (
        <p className="bg-turquoise w-full border-1 p-5 border border-[#B9B9B9]
        text-white text-center font-georgia text-lg font-semibold leading-[130%] max-lg:text-sm">
          Стоимость уточняйте по телефону   <a
            href={`tel:${phoneContent}`}
            className="text-white text-lg font-georgia font-semibold hover:text-hover animation-hover  max-lg:text-sm"
          >
            {phoneContent}
          </a> </p>
      ) : (
        <table className="table-fixed overflow-y-auto">
          <thead>
            <tr className='bg-turquoise'>
              <th className='w-1/6 border-1 p-5 border border-[#B9B9B9]
           text-white text-center font-georgia text-lg font-semibold leading-[130%] max-lg:text-sm'>
                Вид работ
              </th>
              {serviceDataApi.map((category, index) => (
                <th key={index} className='w-1/6 border-1 p-5 border border-[#B9B9B9]
             text-white text-center font-georgia text-lg font-semibold leading-[130%] max-lg:text-sm'>
                  {category.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {uniqueServices.map((service, serviceIndex) => (
              <tr key={serviceIndex} className="even:bg-[#D1E7E4] odd:bg-white">
                <td className='w-1/6 p-5 border-1 border border-[#B9B9B9]
             text-black text-center font-georgia text-lg font-normal leading-[130%] max-lg:text-sm'>
                  {service}
                </td>
                {serviceDataApi.map((category, categoryIndex) => {
                  const matchingService = category.services.find(serviceObj => serviceObj.name === service);
                  const price = matchingService ? `от ${formatNumber(parseFloat(matchingService.price).toFixed(0))} ₽` : '';
                  return (
                    <td key={categoryIndex}
                      className='w-1/6 p-5 border-1 border border-[#B9B9B9] 
                text-black text-center font-georgia  text-lg font-normal leading-[130%] max-lg:text-sm'>
                      {price}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CategoryTable;
