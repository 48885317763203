import { consultation, contract, meeting, work } from "../assets";


export const API_URL = 'https://admin.mera-prava.ru';


export const navLinks = [
  { href: "/about", label: "О компании" },
  { href: "", label: "Услуги" },
  { href: "", label: "Блог" },
  { href: "/price", label: "Стоимость" },
  { href: "#contact", label: "Контакты" },
  { href: "#reviews", label: "Отзывы" }
];


export const stagesWork = [
  { id: 1, image: consultation, title: 'Консультация', description: "Предварительная консультация по телефону или онлайн", dots: true },
  { id: 2, image: meeting, title: 'Встреча', description: "Изучим имеющиеся документы, оценим перспективы", dots: true },
  { id: 3, image: contract, title: 'Заключение договора', description: "Составим и подпишем договор согласно законам РФ", dots: true },
  { id: 4, image: work, title: 'Работа юристов', description: "На ваше дело назначается профильный юрист и защищает вас", dots: false },
]

