import { arrowUp, closeInfo, email, infoContact, phoneInfo, telegram, vk, whatsup } from "../assets";
import { useEffect, useState } from "react";

import { useApi } from "../api/ApiProvider";

const ContactAndScroll = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const { emailContent, tgContent, waContent, phoneContent } = useApi();

    const messengers = [
        { name: 'email', icon: email, link: `mailto:${emailContent}` },
        { name: 'whatsApp', icon: whatsup, link: waContent },
        { name: 'telegram', icon: telegram, link: tgContent },
        { name: 'phone', icon: phoneInfo, link: `tel:${phoneContent}` },
    ];

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setIsExpanded(false);
    };

    const checkScroll = () => {
        if (window.scrollY > 400) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", checkScroll);
        return () => window.removeEventListener("scroll", checkScroll);
    }, []);

    return (
        <div>
            {showTopBtn && (
                <div
                    className={`fixed z-20 ${isExpanded ? "bottom-[360px]" : "bottom-[90px]"
                        } right-0 flex flex-col items-end`}
                >
                    <button onClick={goToTop} className="transition-all duration-300 ">
                        <img src={arrowUp} alt="up" />
                    </button>
                </div>
            )}

            <div className="fixed z-10 bottom-4 right-0 flex flex-col items-end">
                <div
                    className={`flex flex-col space-y-4 mr-6 animation-hover ${isExpanded ? "opacity-100" : "opacity-0 invisible"
                        }`}
                >
                    {messengers.map((messenger) => (
                        <a
                            key={messenger.name}
                            href={messenger.link}
                            target="_blank"
                            className={`p-3 rounded-full bg-[#00AB91] animation-hover hover:bg-opacity-75 `}
                        >
                            <img src={messenger.icon} alt="icon" />
                        </a>
                    ))}
                </div>
                <button onClick={toggleExpanded} className="animation-hover ">
                    {isExpanded ? (
                        <img src={closeInfo} alt="close" />
                    ) : (
                        <img src={infoContact} alt="info" />
                    )}
                </button>
            </div>
        </div>
    );
};

export default ContactAndScroll;
