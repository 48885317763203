import { Helmet } from "react-helmet";
import { Blog, MainScreen } from "../components/arcticle"
import { useEffect, useState } from "react";
import { fetchBlogCategories } from "../api";


const Article = () => {
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');
  const [headerContent, setHeaderContent] = useState<string>('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogCategories = await fetchBlogCategories();
        const firstCategory = blogCategories[0];
        setMetaTitle(firstCategory.meta_title);
        setMetaDescription(firstCategory.meta_description);
        setHeaderContent(firstCategory.name);
      } catch (error) {
        console.error('Ошибка запроса:', error);
      }
    };

    fetchData();
  }, []);



  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <section className="pt-10 max-lg:pt-8 ">
        <MainScreen headerContent={headerContent} />
      </section>
      <section className="pt-10 max-lg:pt-8">
        <Blog />
      </section>
    </div>
  )
}

export default Article