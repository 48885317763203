import { Footer, Nav } from "../sections";

type Props = {
    children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
    return (
        <div>
            <section>
                <Nav />
            </section>
            {children}
            <section>
                <Footer />
            </section>
        </div>
    );
};

export default Layout;
