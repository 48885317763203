import { email, footer, telegram, vk, whatsup } from '../assets';

import { useApi } from '../api/ApiProvider';

interface ServiceCategory {
  id: number;
  name: string;
  description?: string;
  preview?: string;
  image?: string;
  meta_title: string;
  meta_h1: string;
  meta_description: string;
  meta_keywords?: string | null;
  seo_keyword: string;
}

interface BlogCategory {
  id: number;
  name: string;
  description: string | null;
  preview: string | null;
  image: string | null;
  sort_order: number;
  status: number;
  meta_title: string | null;
  meta_h1: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  seo_keyword: string;
  created_at: string;
  updated_at: string;
}

const Footer = () => {
  const { serviceApiData, blogApiData, phoneContent, addressContent, linkContent,
    emailContent, vkContent, tgContent, waContent } = useApi();

  console.log(serviceApiData)

  return (
    <div className="pt-8 pl-10 max-xl:pl-6 bg-turquoise flex justify-between max-md:flex-col">
      <div className="flex flex-col mt-16 max-md:mt-5">
        <a href="/" className="uppercase text-white font-bold font-georgia text-4xl max-[500px]:text-xl
       hover:text-hover animation-hover ">
          Мера права
        </a>
        <div className="flex gap-5 mt-14">
          <a href={`mailto:${emailContent}`} className='animation-hover rounded-full hover:bg-hover '>
            <img src={email} alt="email" />
          </a>
          <a href={tgContent} target="_blank" className='animation-hover rounded-full hover:bg-hover' >
            <img src={telegram} alt="telegram" />
          </a>
          <a href={waContent} target="_blank" className='animation-hover rounded-full hover:bg-hover' >
            <img src={whatsup} alt="whatsup" />
          </a>
          <a href={vkContent} target="_blank" className='animation-hover rounded-full hover:bg-hover'>
            <img src={vk} alt="vk" />
          </a>
        </div>
      </div>
      <div className="flex gap-20 mt-16 mb-10 max-[1300px]:flex-col max-[1300px]:gap-10">
        <div>
          <h2 className="text-white font-georgia text-base font-bold tracking-wide leading-6 uppercase mb-5">Основное</h2>
          <div className="text-white font-georgia text-base font-normal leading-9 flex flex-col cursor-pointer">
            <a href="/" className="text-white hover:text-hover animation-hover ">
              Главная
            </a>
            <a href="#contact" className=" text-white hover:text-hover animation-hover">
              Контакты
            </a>
            <a href={`tel:${phoneContent}`} className="text-white hover:text-hover animation-hover">
              {phoneContent}
            </a>
            <a href={`mailto:${emailContent}`} className='text-white hover:text-hover animation-hover'>
              {emailContent}
            </a>
            <a href={linkContent} target="_blank" className="text-white hover:text-hover animation-hover">
              {addressContent}
            </a>
          </div>
        </div>
        <div>
          <h2 className="text-white font-georgia text-base font-bold tracking-wide leading-6 uppercase mb-5">Направления</h2>
          <div className="text-white font-georgia text-base font-normal leading-9 flex flex-col cursor-pointer">
            {serviceApiData.map((category: ServiceCategory) => (
              <a
                key={category.name}
                href={`/${category.seo_keyword}`}
                className="text-white hover:text-hover animation-hover"
              >
                {category.name}
              </a>
            ))}
          </div>
        </div>
        <div>
          {blogApiData.length ? (
            <>
              <h2 className="text-white font-georgia text-base font-bold tracking-wide leading-6 uppercase mb-5">Полезное</h2>
              <div className="text-white font-georgia text-base font-normal leading-9 flex flex-col cursor-pointer">
                {blogApiData.map((blog: BlogCategory) => (
                  <a
                    key={blog.name}
                    href={`/blog/${blog.seo_keyword}`}
                    className="text-white hover:text-hover animation-hover"
                  >
                    {blog.name}
                  </a>
                ))}
              </div>
          </>
          ) : null}
        </div>
      </div>
      <div className="flex justify-end items-end ">
        <img src={footer} alt="footer" className="w-[300px] h-[300px]" />
      </div>
    </div>
  );
};

export default Footer;
