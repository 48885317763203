
import { phone } from "../../assets";
import { useApi } from "../../api/ApiProvider";


const Contact = () => {
  const { phoneContent, addressContent, timeContent, infoContent, linkContent, mapContent } = useApi();

  return (
    <div className="" id="contact">
      <div className="flex flex-col justify-center items-center ">
        <h1 className="text-black font-georgia text-[30px] font-bold mb-5 max-xl:text-xl max-lg:text-lg max-lg:mb-2 ">
          Контакты
        </h1>
      </div>
      <div className="flex justify-between mt-10  pl-20  max-[1700px]:pl-0  
                max-[2560px]:justify-center max-[1700px]:flex-col max-[1700px]:items-center max-lg:mt-[5px]  ">
        <div className="flex flex-col items-center  max-[2560px]:ml-[15%] max-[1920px]:ml-0">
          <div className="flex mb-[46px] mt-14 max-[1700px]:mt-5 max-lg:mb-[20px]" >
            <img src={phone} alt="phone" className="max-xl:w-8 max-lg:w-6" />
            <a className="text-black font-georgia font-bold text-[30px]  
                            text-center hover:text-darkgreen animation-hover
                            max-xl:text-2xl  max-lg:text-xl " href={`tel:${phoneContent}`}>
              {phoneContent}
            </a>
          </div>
          <div className="text-black font-georgia font-normal text-base text-center 
                        max-lg:text-sm ">
            <a href={linkContent} target="_blank"
              className="text-black hover:text-darkgreen animation-hover">{addressContent}</a>
            <p className="mb-5 mt-5">{infoContent}</p>
            <p className="mb-5">{timeContent}</p>
          </div>
        </div>
        <div className="w-full flex justify-center pl-10 pr-10">
          <div dangerouslySetInnerHTML={{ __html: mapContent || "" }} />
        </div>
      </div>
    </div>

  )
}

export default Contact;
