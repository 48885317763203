
import { useEffect, useState } from 'react';
import { fetchMainData } from './api';
import { ApiProvider } from './api/ApiProvider';
import { useRoutes } from './routes/routes';
import { Helmet } from 'react-helmet';
import { API_URL } from './constants';


function App() {
  const [faviconContent, setFaviconContent] = useState('');
  const fetchData = async () => {
    try {
      const mainData = await fetchMainData();
      setFaviconContent(mainData.favicon.replace("public", "storage"));

    } catch (error) {
      console.error('Ошибка запроса:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const routes = useRoutes();
  return (
    <ApiProvider>
      <Helmet>
        <link rel="icon" href={`${API_URL}/${faviconContent}`} />
      </Helmet>
      {routes}
    </ApiProvider>
  );
}

export default App;
