import { AboutCompany, Article, Home, Price, ServicesCategory } from "../pages";
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useParams,
} from "react-router-dom";

import { BlogArticle } from "../components/arcticle";
import Layout from "../layouts/layout";

export const useRoutes = () => {
    const ServiceCategoryRoute = () => {
        const { seo_keyword } = useParams();
        const seoKeyword = seo_keyword || "";

        return <ServicesCategory seoKeyword={seoKeyword} />;
    };
    const BlogRoute = () => {
        const { seo_keyword } = useParams();
        const seoKeyword = seo_keyword || "";

        return <BlogArticle seoKeyword={seoKeyword} />;
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Layout>
                            <Home />
                        </Layout>
                    }
                />
                <Route
                    path="/main"
                    element={
                        <Layout>
                            <Home />
                        </Layout>
                    }
                />
                <Route
                    path="/about"
                    element={
                        <Layout>
                            <AboutCompany />
                        </Layout>
                    }
                />
                <Route
                    path="/blog/stati"
                    element={
                        <Layout>
                            <Article />
                        </Layout>
                    }
                />
                <Route
                    path="/price"
                    element={
                        <Layout>
                            <Price />
                        </Layout>
                    }
                />
                <Route
                    path="/articles/:seo_keyword"
                    element={
                        <Layout>
                            <BlogRoute />
                        </Layout>
                    }
                />
                <Route
                    path="/:seo_keyword"
                    element={
                        <Layout>
                            <ServiceCategoryRoute />
                        </Layout>
                    }
                />
                <Route
                    path="/:random"
                    element={
                        <Layout>
                            <Home />
                        </Layout>
                    }
                />
                <Route path="*" element={<Navigate to="/main" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default useRoutes;
