
interface Service {
    id: number;
    name: string;
    price: string;
    description: string | null;
    preview: string | null;
}
interface ServicesPriceProps {
    priceContent: Service[];
}
const ServicesPrice = ({ priceContent }: ServicesPriceProps) => {
    const formatNumber = (number: string) => {
        const parsedNumber = typeof number === 'string' ? parseFloat(number) : number;
        return new Intl.NumberFormat('ru-RU').format(parsedNumber);
    };

    return (
        <div className="flex flex-col justify-center items-center">
            <h1 className="text-black font-georgia text-[30px] font-bold mb-5 max-xl:text-xl max-lg:text-lg max-lg:mb-2">
                Стоимость услуг
            </h1>
            <div className="grid grid-cols-2 max-lg:grid-cols-1 justify-center items-start max-xl:pl-10
            w-full max-w-screen-lg max-lg:flex-col max-lg:px-24 max-lg:items-center max-md:px-14">
                {priceContent.map((service, serviceIndex) => (
                    <div key={serviceIndex} className="w-full pr-10 max-lg:pr-0">
                        <div>
                            <div className="flex mt-5 items-center justify-between max-[450px]:flex-col max-[450px]:items-start">
                                <h1 className="text-black font-georgia text-base font-bold  uppercase   max-md:text-sm">
                                    {service.name}
                                </h1>
                                <p className="text-black text-right font-georgia text-sm font-bold  w-32 max-md:text-sm max-[450px]:text-left">
                                    {`от ${formatNumber(parseFloat(service.price).toFixed(0))} ₽`}
                                </p>
                            </div>
                            <hr className="w-full border-t h-[1px] bg-black bg-opacity-10 mt-2 mb-6" />
                            <h2 className="text-black font-georgia text-sm font-normal  ">{service.preview}</h2>
                        </div>
                    </div>
                ))}
            </div>
            <hr className="mt-12 w-full border-t h-[1px] border-[#1B877A]" />
        </div>
    )
}

export default ServicesPrice