import { mainBanner, mainBannerAdaptive } from "../../assets";

import { Link as ScrollLink } from 'react-scroll';

const MainScreen = () => {
  return (
    <div className="relative flex items-center justify-center">
      <img
        src={mainBanner}
        alt="banner"
        className="max-sm:hidden block object-cover"
      />
      <img
        src={mainBannerAdaptive}
        alt="adaptive-banner"
        className="max-sm:block hidden w-full h-auto object-cover "
      />

      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  text-white text-center ">
        <div
          className="flex flex-col justify-center items-center rounded-[20px] shadow-md 
          w-[900px] h-[350px] max-[1440px]:w-[850px]  max-xl:w-[600px] max-xl:h-[260px]  max-lg:w-[500px] max-lg:h-[240px]
          max-md:w-[450px]  max-md:h-[200px]  max-[500px]:w-[420px] max-[450px]:w-[400px] max-[400px]:w-[300px] max-[425px]:h-[170px] 
      "
          style={{
            borderRadius: "20px",
            background: "rgba(255, 255, 255, 0.10)",
            backdropFilter: "blur(1.500000047683716px)",
            WebkitBackdropFilter: "blur(1.500000047683716px)",
            boxShadow: "16.14769px 20.18461px 30.27692px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <h1 className="text-center text-[#0F0D0D] font-georgia  leading-tight mt-5 text-[42px] font-bold mb-10
            max-[1440px]:text-[38px] max-[1440px]:mb-8  max-xl:text-4xl max-lg:text-3xl px-16 max-md:px-8
            max-md:text-2xl max-md:mb-8 max-[425px]:text-xl  max-[425px]:mb-3">
            Профессиональная  юридическая защита  Ваших интересов
          </h1>

          <ScrollLink
            to="online-booking"
            smooth={true} className="rounded-2xl bg-turquoise px-12 py-5 text-white text-center cursor-pointer mb-5
            font-georgia text-base font-bold uppercase animation-hover hover:bg-darkgreen hover:text-white 
            max-[1440px]:px-8  max-[1440px]:py-4  max-lg:text-xs max-lg:py-3  max-md:py-2  max-md:px-4 
             max-[425px]:text-[10px] max-[425px]:px-2 max-[425px]:py-2  ">
            Онлайн запись
          </ScrollLink>
        </div>

      </div>
    </div>

  );
};

export default MainScreen;
