import { useEffect, useState } from "react";
import { MainScreen } from "../components/about"
import { Consultation, Contact } from "../components/home"
import { ContactAndScroll } from "../sections";
import { fetchInfoData } from "../api";
import { Helmet } from "react-helmet";
import { useApi } from "../api/ApiProvider";



const AboutCompany = () => {
    const [metaTitle, setMetaTitle] = useState<string>('');
    const [metaDescription, setMetaDescription] = useState<string>('');
    const [headerContent, setHeaderContent] = useState<string>('');
    const [descriptionContent, setDescriptionContent] = useState<string>('');

    const { serviceApiData} = useApi();
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const aboutData = await fetchInfoData();
                setMetaTitle(aboutData.data[0].meta_title);
                setMetaDescription(aboutData.data[0].meta_description);
                setHeaderContent(aboutData.data[0].name)
                setDescriptionContent(aboutData.data[0].description);
            } catch (error) {
                console.error('Ошибка запроса:', error);
            }
        };

        fetchData();

    }, []);

    return (
        <div>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
            </Helmet>
            <section>
                <ContactAndScroll />
            </section>
            <section className="pt-10 max-lg:pt-8 ">
                <MainScreen headerContent={headerContent} />
            </section>
            <div dangerouslySetInnerHTML={{ __html: descriptionContent || '' }} />
            <section className="pt-16">
                <Consultation serviceApiData={serviceApiData} />
            </section>
            <section className="pt-7">
                <Contact />
            </section>

        </div>
    )
}

export default AboutCompany