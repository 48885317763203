import { useState } from "react";
import { arrowDown, mera, question } from "../../assets"
import InputMask from 'react-input-mask';
import { PrivacyPolicy } from "../../sections/Home";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { API_URL } from "../../constants";
import axios from "axios";


interface ServiceCategory {
    id: number;
    name: string;
    description?: string;
    preview?: string;
    image?: string;
    meta_title: string;
    meta_h1: string;
    meta_description: string;
    meta_keywords?: string | null;
    seo_keyword: string;
}

interface ConsultationProps {
    serviceApiData: ServiceCategory[];
}
const Consultation = ({ serviceApiData }: ConsultationProps) => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
    const toggleForm = () => {
        setIsFormOpen(!isFormOpen);
    };
    const {
        register,
        handleSubmit,
        reset,
        setValue,
    } = useForm()

    const recordForm: SubmitHandler<FieldValues> = async (data) => {
        try {
            const currentUrl = window.location.href;
            data.url = currentUrl;
            if (data.text) {
                data.text = `Запись на консультацию. Вид обращения: [${data.text}]`;
            } else {
                data.text = "Запись на консультацию";
            }
            const response = await axios.post(
                `${API_URL}/api/feedback/store`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                console.log("Данные успешно отправлены");
                reset();
                setErrors({});
                setValue("phone", "");
            }
        } catch (error: any) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error("Ошибка запроса:", error);
            }
        }
    };

    return (
        <div className="bg-no-repeat bg-left " id='online-booking' style={{ backgroundImage: `url(${mera})`, }}>
            <div className="flex justify-between mr-20 max-[2560px]:justify-center  max-2xl:mr-0">
                <div
                    className="flex items-center justify-center  max-[2560px]:mr-40  
                rounded-[20px] shadow-md w-[700px] h-[180px] bg-white ml-32 mt-12 max-xl:flex-col max-2xl:hidden"
                    style={{
                        borderRadius: "20px",
                        background: "rgba(255, 255, 255, 0.10)",
                        backdropFilter: "blur(6.300000190734863px)",
                        WebkitBackdropFilter: "blur(6.300000190734863px)",
                        boxShadow: "16.14769px 20.18461px 30.27692px 0px rgba(0, 0, 0, 0.25)",
                    }}
                >
                    <img
                        src={question}
                        alt="question"
                        className="p-10 "
                    />
                    <h2 className="text-black text-left font-georgia text-2xl font-semibold leading-normal 
                     max-[1700px]:text-xl  max-2xl:ml-2 ">Остались вопросы? <br />
                        Запишись на консультацию к профильному юристу, адвокату!</h2>
                </div>
                <div className=" bg-turquoise w-[900px] rounded-xl max-[1200px]:w-full max-[1200px]:mx-20  max-md:mx-5"
                    style={{
                        boxShadow: "0px 4.95623px 4.95623px 0px rgba(0, 0, 0, 0.25);"
                    }}>
                    <h1 className="text-white font-georgia font-bold text-[36px] mt-12 text-center uppercase 
                   max-[1700px]:text-4xl max-[1550px]:text-3xl max-[1440px]:text-2xl  
                   max-xl:text-xl max-lg:text-base max-md:text-sm max-[500px]:text-xs ">
                        записаться на консультацию</h1>
                    <form className="p-12 max-md:p-5" onSubmit={handleSubmit(recordForm)} >

                        <div className="flex items-center mt-10 justify-between mb-5 max-lg:mt-2 max-md:flex-col ">
                            <label className="w-[250px]  max-md:w-full">
                                <span className="text-white font-georgia text-xl font-medium max-md:text-sm mr-2"> ФИО </span>
                            </label>
                            <div className=" flex flex-col w-full ">
                                <input type="text" className="flex-grow rounded-[10px] h-12 pl-2  font-georgia text-xl font-medium
                             max-[1440px]:text-base max-md:text-sm max-[500px]:h-10 max-md:w-full" placeholder="Введите ФИО"
                                    {...register("author")} />
                                {errors.author && (
                                    <div className="text-red-400">{errors.author[0]}</div>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center justify-between mb-5  max-md:flex-col">
                            <label className="w-[250px]  max-md:w-full">
                                <span className="text-white font-georgia text-xl font-medium max-md:text-sm  mr-2"> Номер телефона</span>
                            </label>
                            <div className=" flex flex-col w-full ">
                                <InputMask
                                    mask="+7 (999) 999-99-99"
                                    className="flex-grow rounded-[10px] h-12 pl-2 font-georgia text-xl font-medium
                                max-[1440px]:text-base max-md:text-sm max-[500px]:h-10  max-md:w-full"
                                    placeholder="Введите номер телефона"
                                    {...register("phone")}
                                />
                                {errors.phone && (
                                    <div className="text-red-400">{errors.phone[0]}</div>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center justify-between mb-5 max-md:flex-col ">
                            <label className="w-[250px]  max-md:w-full">
                                <span className="text-white font-georgia text-xl font-medium max-md:text-sm  mr-2"> Адрес электронной почты</span>
                            </label>
                            <div className=" flex flex-col w-full ">
                                <input type="text" className="flex-grow rounded-[10px] h-12 pl-2 font-georgia text-xl font-medium
                             max-[1440px]:text-base max-md:text-sm max-[500px]:h-10  max-md:w-full" placeholder="Введите адрес электронной почты"
                                    {...register("email")} />
                                {errors.email && (
                                    <div className="text-red-400">{errors.email[0]}</div>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center justify-between mb-5 relative max-md:flex-col ">
                            <label className="w-[250px] max-md:w-full">
                                <span className="text-white font-georgia text-xl font-medium max-md:text-sm  mr-2 "> Вид обращения</span>
                            </label>
                            <div className="w-full flex flex-col">
                                <select
                                    className="flex-grow rounded-[10px] h-12 text-black font-georgia text-xl font-medium appearance-none px-4 py-2
                            max-[1440px]:text-base max-md:text-xs max-md:px-1 max-[500px]:h-10 max-md:w-full"
                                    {...register("text")}>
                                    <option value="">Выберите вид обращения</option>
                                    {serviceApiData.map((category: any) => (
                                        <option key={category.name} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 max-md:mt-6">
                                <img src={arrowDown} alt="arrow" />
                            </div>

                        </div>
                        <div className="flex justify-end mt-10  max-[500px]:mt-5 ">
                            <button className="animation-hover hover:bg-slate-200 bg-white shadow-md 
                            rounded-[10px] px-8 py-4 text-[#202E40] font-georgia text-center
                             text-lg font-bold  uppercase max-md:text-sm max-md:py-4 max-[500px]:py-2">отправить</button>
                        </div>
                    </form>
                    <div className="flex justify-center items-center mb-10  ">
                        <p className="text-center text-white font-georgia font-normal text-[13px] leading-5
                         max-md:text-[12px]  max-[500px]:px-2">Нажимая на кнопку, вы даете согласие на обработку персональных данных <br />  и соглашаетесь c
                            <span className=" underline cursor-pointer" onClick={toggleForm} > политикой конфиденциальности</span></p>
                    </div>
                </div >
                {isFormOpen && <PrivacyPolicy toggleForm={toggleForm} />}
            </div >
            <hr className="mt-16 w-full border-t h-[1px] border-[#1B877A] " />
        </div >
    )
}

export default Consultation 