import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { fetchCategoryData, fetchServiceCategories } from "../api";
import { ContactAndScroll } from "../sections";
import { Consultation, Contact } from "../components/home";
import { MainScreen, Question, ServicesPrice, ServicesReviews } from "../sections/Services";



interface Category {
  id: number;
  category_id: number | null;
  name: string;
  description: string | null;
  preview: string | null;
  image: string | null;
  sort_order: number;
  status: number;
  meta_title: string | null;
  meta_h1: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  seo_keyword: string | null;
  created_at: string;
  updated_at: string;
}
interface Review {
  id: number;
  category_id: number;
  author: string;
  text: string;
  sort_order: number;
  status: number;
  date_available: string;
  created_at: string;
  updated_at: string;
  category: Category;
}

interface ServiceCategoryProps {
  seoKeyword: string;
}
interface Service {
  id: number;
  name: string;
  price: string;
  description: string | null;
  preview: string | null;
}

interface ServiceCategory {
  id: number;
  name: string;
  description?: string;
  preview?: string;
  image?: string;
  meta_title: string;
  meta_h1: string;
  meta_description: string;
  meta_keywords?: string | null;
  seo_keyword: string;
}

const ServicesCategory: React.FC<ServiceCategoryProps> = ({ seoKeyword }) => {
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');
  const [descriptionContent, setDescriptionContent] = useState<string>('');
  const [headerContent, setHeaderContent] = useState<string>('');
  const [priceContent, setPriceContent] = useState<Service[]>([]);
  const [reviewData, setReviewData] = useState<Review[]>([]);
  const [nameContent, setNameContent] = useState<string>('');
  const [serviceApiData, setServiceApiData] = useState<ServiceCategory[]>([]);

  const fetchMetaInfo = async () => {
    try {
      const categoryData = await fetchCategoryData(seoKeyword);

      setMetaTitle(categoryData.category.meta_title);
      setMetaDescription(categoryData.category.meta_description);
      setDescriptionContent(categoryData.category.description);
      setHeaderContent(categoryData.category.meta_h1);
      setPriceContent(categoryData.services.data);
      setReviewData(categoryData.reviews.data);
      setNameContent(categoryData.category.name);
  
      const serviceData = await fetchServiceCategories();
      setServiceApiData(serviceData);
    } catch (error) {
      console.error('Ошибка запроса:', error);
    }
  };

  useEffect(() => {
    fetchMetaInfo();
  }, [seoKeyword]);

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <section>
        <ContactAndScroll />
      </section>
      <section className="pt-10 max-lg:pt-8 ">
        <MainScreen headerContent={headerContent} seoKeyword={seoKeyword} />
      </section>
      <section className="pt-10 ">
        <Question descriptionContent={descriptionContent} />
      </section>
      <section className="pt-10 ">
        <ServicesPrice priceContent={priceContent} />
      </section>
      <section className="pt-16">
        <Consultation serviceApiData={serviceApiData} />
      </section>
      <section className="pt-5">
        <ServicesReviews reviewData={reviewData} nameContent={nameContent} />
      </section>
      <section className="pt-7">
        <Contact />
      </section>
    </div>
  );
}

export default ServicesCategory